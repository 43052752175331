<template>
    <v-expansion-panel>
        <v-expansion-panel-header>
            <template v-if="business">Actualizar</template>
            <template v-else>Añadir</template>
            Negocio
            <template v-slot:actions>
                <v-icon color="error" @click="$store.dispatch('deleteWindowByUuid',{uuid : win.uuid})">
                    mdi-close
                </v-icon>
            </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
            <v-card>
                <v-tabs
                        v-model="tab"
                        dark background-color="primary"
                        icons-and-text
                >
                    <v-tabs-slider color="red"></v-tabs-slider>

                    <v-tab href="#tab-1">
                        <template v-if="business">Actualizar</template>
                        <template v-else>Añadir</template>
                        Negocio
                        <v-icon>mdi-plus</v-icon>
                    </v-tab>
                </v-tabs>

                <v-tabs-items v-model="tab">
                    <v-tab-item value="tab-1">
                        <v-card class="white" flat>
                            <v-card-text>
                                <manage-business :business="business" :win="win"></manage-business>
                            </v-card-text>
                        </v-card>
                    </v-tab-item>
                </v-tabs-items>
            </v-card>
        </v-expansion-panel-content>
    </v-expansion-panel>
</template>

<script>
    import ManageBusiness from "./ManageBusiness";

    export default {
        name: "AddBusiness",
        components: {ManageBusiness},
        props: ['win'],
        data: () => ({
            valid: false,
            form: {},
            url: null,
            image: null,
            tab: null,
            business: null
        }),
        mounted() {
            this.business = this.win.meta.business;
        },
        methods: {
            Preview_image() {
                this.url = URL.createObjectURL(this.image)
            },
            reloadData(payload) {
                this.$emit('reloadData', payload)
            }
        }
    }
</script>

<style scoped>

</style>
